import { mergeDeep } from "../../services/utils";
// core
import en_default from "../core/l10n/en/en";
import es_default from "../core/l10n/es/es";
import pt_default from "../core/l10n/pt/pt";
// custom
import custom_en_default from "./l10n/en/en";
import custom_es_default from "./l10n/es/es";
import custom_pt_default from "./l10n/pt/pt";

const resources = {
	en: {
		default: mergeDeep(en_default, custom_en_default),
	},
	es: {
		default: mergeDeep(es_default, custom_es_default),
	},
	pt: {
		default: mergeDeep(pt_default, custom_pt_default),
	},
} as const;

export default resources;
